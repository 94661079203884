import React, { useEffect, Fragment } from "react"
import "../css/slider.css"
import Img1 from "../images/Bck1.jpg"
import Img1Min from "../images/Bck1-min.jpg"

import Img2 from "../images/products-bg.jpg"
import Img2Min from "../images/products-bg-min.jpg"

import Img3 from "../images/Bck7.jpg"
import Img3Min from "../images/Bck7-min.jpg"

import AwesomeSlider from "react-awesome-slider"
import "../css/slider.css"
import "aos/dist/aos.css"
import AOS from "aos"
import withAutoplay from "react-awesome-slider/dist/autoplay"
import "react-awesome-slider/dist/styles.css"
import "../css/awesomeSlider.css"

import ProgressiveImage from "react-progressive-image-loading"

const AutoplaySlider = withAutoplay(AwesomeSlider)

const Slider = () => {
  useEffect(() => {
    AOS.init({
      once: true,
    })
  })
  return (
    <Fragment>
      <div className="background">
        <AutoplaySlider
          fillParent={true}
          bullets={false}
          infinite={true}
          interval={6000}
          className="aws-btn"
          play={true}
        >
          <div className="slides">
            <ProgressiveImage
              preview={Img1Min}
              src={Img1}
              render={(src, style) => <img src={Img1} style={style} />}
            />
          </div>
          <div className="slides">
            <ProgressiveImage
              preview={Img2Min}
              src={Img2}
              render={(src, style) => <img src={Img2} style={style} />}
            />
          </div>
          <div className="slides">
            <ProgressiveImage
              preview={Img3Min}
              src={Img3}
              render={() => <img src={Img3} />}
            />
          </div>
        </AutoplaySlider>
        <div className="overlay">
          <h1 className="has-text-white is-size-2 is-uppercase">
            Fabrika šavnih cevi i servisni centar za obradu metala
          </h1>
        </div>
      </div>
    </Fragment>
  )
}

export default Slider
