import React, { Fragment, useEffect } from "react"
import { Link } from "gatsby"
import Navbar from "../components/Navbar"
import "../css/style.css"
import "../css/mystyle.scss"
import Slider from "../components/Slider"
import Footer from "../components/Footer"
import ProductCard from "../components/ProductCard"
import BgImg from "../images/Bck3.jpg"
import BgImgMin from "../images/Bck3-min.jpg"
import PvcImg from "../images/pvcojacanja2.jpg"
import KlizneImg from "../images/klizne1.jpg"
import StraniceImg from "../images/stranice.jpg"
import NosaciImg from "../images/nosaci.jpg"
import SavneImg from "../images/savne.jpg"
import ProfilisaniImg from "../images/profilisani.jpg"
import PocinkovaniImg from "../images/pocinkovanilim.jpg"
import HladnovaljaniImg from "../images/hladnovaljani.jpg"
import ToplovaljaniImg from "../images/toplovaljani.jpg"
import ToplovaljaniRebrastiImg from "../images/tvrebrasti.jpg"
import ToplovaljaniKotlovskiImg from "../images/tvkotlovski.jpg"
import SineImg from "../images/sine1.jpg"
import PocinkovaneImg from "../images/pocinkovane1.jpg"
import ProgressiveImage from "react-progressive-image-loading"
import HopImg from "../images/hop-profili.jpg"

import "aos/dist/aos.css"
import AOS from "aos"
import ReactPlayer from "react-player"
import Layout from "../components/layout"
import ServiceCard from "../components/ServiceCard"
import Head from "../components/head"

const IndexPage = ({ data }) => {
  useEffect(() => {
    // Aos
    AOS.init({
      once: true,
      delay: 100,
    })

    // //Video Muted
    // let video = document.getElementById("video-item")
    // video.setAttribute("muted", "true")
  }, [])
  return (
    <Layout>
      <Head title="DAK Comerc | proizvodnja šavnih cevi i servisni centar za obradu metala" />
      <Slider className="slider" />
      <section className="intro-section">
        <h3>
          DAK
          <br />
          Znak za metal
        </h3>
        <h4>NOVOM ENERGIJOM NASTOJIMO DA UNAPREDIMO TRŽIŠTE CEVI</h4>
      </section>
      <hr id="separator" />
      <section className="container products">
        <h3>PROIZVODI</h3>
        <div className="columns is-mobile is-multiline">
          <ProductCard
            to="/proizvodi/šavne-cevi"
            title="Šavne cevi"
            img={SavneImg}
          />
          <ProductCard
            title="Toplovaljani limovi"
            img={ToplovaljaniImg}
            to="/proizvodi/toplovaljani-lim"
          />
          <ProductCard
            title="Toplovaljani rebrasti limovi"
            img={ToplovaljaniRebrastiImg}
            to="/proizvodi/toplovaljani-rebrasti-limovi"
          />
          <ProductCard
            title="Toplovaljani kotlovski limovi"
            img={ToplovaljaniKotlovskiImg}
            to="/proizvodi/toplovaljani-kotlovski-limovi"
          />
          <ProductCard
            title="Hladnovaljani limovi"
            img={HladnovaljaniImg}
            to="/proizvodi/hladnovaljani-limovi"
          />
          <ProductCard
            title="Pocinkovani limovi"
            img={PocinkovaniImg}
            to="/proizvodi/pocinkovani-limovi"
          />
          <ProductCard
            to="/proizvodi/profili-nosači"
            title="Profili i nosači"
            img={NosaciImg}
          />
          <ProductCard
            to="/proizvodi/pocinkovane-cevi"
            title="Pocinkovane cevi"
            img={PocinkovaneImg}
          />
          <ProductCard
            to="/proizvodi/klizne-šine"
            title="Klizne šine"
            img={SineImg}
          />
          <ProductCard
            to="/proizvodi/profilisani-limovi"
            title="Profilisani limovi"
            img={ProfilisaniImg}
          />

          <ProductCard
            title="PVC ojačanja"
            img={PvcImg}
            to="/proizvodi/pvc-ojačanja"
          />
          <ProductCard
            title="CD/UD
Profili"
            img={KlizneImg}
            to="/proizvodi/cd-ud-profili"
          />
          <ProductCard
            title="Čelične stranice"
            img={StraniceImg}
            to="/proizvodi/čelične-stranice"
          />
          <ProductCard
            title="HOP Profili"
            img={HopImg}
            to="/proizvodi/hop-profili"
          />
        </div>
      </section>
      <section
        className="products-short"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <p className="is-size-2-desktop">
          OD NAS SVE POČINJE
          <br /> OBLIKUJEMO BUDUĆNOST
        </p>
        <Link
          to="/o-nama"
          className="button is-link is-size-5-desktop is-size-8-mobile"
        >
          O NAMA
        </Link>
        <ProgressiveImage
          preview={BgImgMin}
          src={BgImg}
          render={(src, style) => (
            <div
              className="blur-bg"
              style={Object.assign(style, {
                backgroundImage: `url(${src})`,
              })}
            />
          )}
        />
        <div className="black-overlay"></div>
      </section>
      <section className="values  is-fullwidth">
        <div className="container">
          <h3
            className="has-text-link"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            Vrednosti za koje se zalažemo
          </h3>
          <hr id="w-separator" />

          <div className="columns ">
            <div className="column" data-aos="fade-up" data-aos-duration="1000">
              <i className="fas fa-cogs fa-4x"></i>
              <p>
                Radimo zajedno u cilju stvaranja održive vrednosti, kvaliteta
                proizvoda, profesionalizma usluga za tržište crne i obojene
                metalurgije, održavajući poslovnu etiku i moral kompanije.
              </p>
            </div>
            <div className="column" data-aos="fade-up" data-aos-duration="1000">
              <i className="fas fa-handshake fa-4x"></i>
              <p>
                Sopstvenom proizvodnjom cevi garantujemo kvalitet proizvoda i
                obezbedjujemo širok asortiman robe iz oblasti crne I objene
                metalurgije, održavajući dugoročno poverenje I maksimalnu
                posvećenost kupcima I njihovim potrebama.
              </p>
            </div>
            <div className="column" data-aos="fade-up" data-aos-duration="1000">
              <i className="fas fa-medal fa-4x"></i>
              <p>
                Potvrda o usaglašenosti kontrole proizvodnje u fabrici, u skladu
                sa Regulacijom (EU) broj 3052011 od Evrposkog parlamenta i
                Saveta iz 09.03.2011. počivaju na usaglašenosti uslova za
                stavljanje u promet građevinskih proizvoda.
              </p>
            </div>
            <div className="column" data-aos="fade-up" data-aos-duration="1000">
              <i className="fas fa-users fa-4x"></i>
              <p>
                Kompanija trenutno zapošljava 130 radnika svih kadrova. Teži ka
                usavršavanju I razvoju svojih zaposlenih, brine o zaštiti
                životne sredine I pozitivno utiče na zajednicu u kojoj posluje.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="video" data-aos="flip-up" data-aos-duration="1000">
        <ReactPlayer
          url="http://www.youtube.com/embed/P0uCOrCWiwU?enablejsapi=1&origin=http://localhost:8000/"
          muted={true}
          loop
          width="100%"
          height="100%"
        />
      </section>
    </Layout>
  )
}

export default IndexPage
